import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { shake } from 'ng-animate';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(shake))])
  ],
})
export class NotFoundComponent implements OnInit {
  bounce: any;
  constructor() { }

  ngOnInit() {
  }

}
