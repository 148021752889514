import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'saint-chamois';

  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'zh']);
    const lang = this.getAppropriateLanguage();
    translate.setDefaultLang(lang);
    translate.use(lang);
   }


   getAppropriateLanguage(): string {
    if (localStorage.getItem('language')) {
      console.log(`your fav lang is in local prefs`);
      return localStorage.getItem('language');
    } else {
      const browserLang = this.translate.getBrowserLang();
      if (browserLang.match(/en|zh/)) {
        console.log(`No fav so here's your browser lang`);
        return browserLang;
      } else {
        console.log(`No fav and no browser lang so here's english`);
        return 'en';
      }
    }
  }
}
