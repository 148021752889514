import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  clickCount = 1;
  audio = new Audio();

  constructor(public router: Router) { }

  ngOnInit() {
  }

  displayVideo() {
    console.log('That\'s when the fun start');
    this.router.navigate(['/on-se-bute']);
  }

  play(){
    console.log('play');
    this.audio.src = '../../../assets/audio/china_girl.mp3';
    this.audio.load();
    this.audio.loop = true;
    this.audio.play();
  }

  chamoisClicked() {
    console.log('keep clicking');

    if (this.clickCount === 1) {
      this.play();
    }

    if (this.clickCount++ >= 30) {
      this.audio.pause();
      this.displayVideo();
    }
  }

}
