import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  HomeComponent,
  NotFoundComponent,
  OnSeButeComponent,
  MandelComponent,
  BruceComponent,
  PoleComponent,
  // AboutComponent,
} from './components';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  // {
  //   path: 'about',
  //   component: AboutComponent,
  // },
  {
    path: 'on-se-bute',
    component: OnSeButeComponent,
  },
  {
    path: 'mandel',
    component: MandelComponent,
  },
  {
    path: 'bruce',
    component: BruceComponent,
  },
  {
    path: 'pole',
    component: PoleComponent,
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path: '**',
  component: NotFoundComponent,
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
