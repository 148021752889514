import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { shake } from 'ng-animate';

@Component({
  selector: 'app-mandel',
  templateUrl: './mandel.component.html',
  styleUrls: ['./mandel.component.scss'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(shake))])
  ],
})
export class MandelComponent implements OnInit {
  bounce: any;
  constructor() { }

  drawMandel() {
    const mandel = document.getElementById('mandel') as HTMLCanvasElement;
    const ctx = mandel.getContext('2d');
    const colors = ['#FFF', '#F6F6F6', '#EEE', '#E6E6E6', '#407', '#224', '#632',
      '#A41', '#F00', '#F52', '#F75', '#EA8', '#953', '#333', '#000'];
    for (let y = 0; y < 1200; y++) {
      for (let x = 0; x < 1600; x++) {
        const z = {a: (x - 1050) / 520, b: (y - 600) / 440};
        let i = 0;
        let z2 = z;
        for (i = 0; i < colors.length - 1; i++) {
          z2 = {a: z2.a * z2.a - z2.b * z2.b + z.a, b: 2 * z2.a * z2.b + z.b};
          if (z2.a * z2.a + z2.b * z2.b > 4) { break; }
        }
        ctx.fillStyle = colors[i];
        ctx.fillRect(x, y, 1, 1);
      }
    }
  }

  ngOnInit() {
    this.drawMandel();
  }

}
